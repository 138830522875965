/*　include
------------------------------------------------------- */

import "core-js/stable";
import "regenerator-runtime/runtime";
import './scss/style.scss';

/*
------------------------------------------------------- */

$('.js_menu-btn').click(function() {
    $('.ly_header').toggleClass('is-animation');
    $('.ly_header').toggleClass('DownMove');
    $('html,body').toggleClass('menu-open');
    $(this).toggleClass('fix');
    $('.ly_menu-content').toggleClass('open');
})

$('.js_menu-btn').on('click', function() {
    setTimeout(function(){
      $('.ly_menu-content.fix').removeClass('fix');
    },900);
})

  $('.js_menu-btn').click(function() {
      $('.ly_menu-content').addClass('fix');
  })

if(navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
    // スマホ・タブレット（iOS・Android）の場合の処理を記述
    $('body').addClass('sp-tb');
}else{
    // PCの場合の処理を記述
    $('body').addClass('pc');
}

$('.js_menu-btn').click(function() {
    setTimeout(function(){
        $('.ly_menu-content__social').toggleClass('fix');
    },800);
})

/* メニュー */
const ham = document.querySelector('.js_menu-btn');
const menu = document.querySelector('.ly_menu-content');
const links = menu.querySelectorAll('.js_menu-item');

var tl = gsap.timeline({ paused: true });

tl.to(menu, {
    duration: 0.6,
    opacity: 1,
    height: '100vh',
    ease: 'expo.inOut',
})
tl.from(links, {
    duration: 0.7,
    opacity: 0,
    y: 20,
    stagger: 0,
    ease: 'expo.inOut',
}, "-=0.1");

tl.reverse();

ham.addEventListener('click', () => {
    tl.reversed(!tl.reversed());
    console.log('click');
});

let webStorage = function(){

if(sessionStorage.getItem('access')){
/* 2回目以降 */
  $('.el_splash').addClass("js_hidden");
  $('.wrap').addClass("js_visible");
  $('header').addClass("js_visible");
} else {
sessionStorage.setItem('access', 'true');
/* 1回目 */

  if (window.matchMedia("(max-width: 835px)").matches) {
    //画面横幅が768px以下のときの処理
  $('.el_splash').addClass("js_hidden");
  $('.wrap').addClass("js_visible");
  $('header').addClass("js_visible");
  } else {

    //画面横幅が769px以上のときの処理
              document.addEventListener('DOMContentLoaded', (event) => {
                const $el = document.querySelector('.el_splash_inner_logo_ttl_img');
                const duration = 2;
                const from = { opacity: 0, ease: Linear.ease };
                const to = { opacity: 1 };
                const delay = 1;
                TweenLite.fromTo($el, duration, from, to, delay);
              });

              document.addEventListener('DOMContentLoaded', (event) => {
                let splashWrap = CSSRulePlugin.getRule(".el_splash");
                let splashItem = CSSRulePlugin.getRule(".el_splash_inner_logo_ttl_img");
                let splashTlA = new TimelineMax({
                  delay: 3,
                })
                .to(splashItem,1,{ease:Power3.easeInOut,cssRule:{top:"60px"}});
              });

              document.addEventListener('DOMContentLoaded', (event) => {
                let splashWrap = CSSRulePlugin.getRule(".el_splash");
                let splashItemB = CSSRulePlugin.getRule(".el_splash_inner-bg");
                let splashTlA = new TimelineMax({
                  delay: 0.55,
                })
                .to(splashItemB,1,{ease:Power3.easeInOut,cssRule:{opacity:"1"}});
              });

              window.setTimeout(function(){
                $(".el_splash").fadeOut(1500);
              }, 4800);

              window.setTimeout(function(){
                $('.wrap').addClass("js_visible");
                $('header').addClass("js_visible");
                $('html').css('overflow', 'scroll');
                $('header').removeClass("js_hidden");
              }, 4500);

              window.setTimeout(function(){
                $('.el_splash_inner_logo_ttl_img').addClass("fixed");
              }, 3700);
  };

}
}
webStorage();

$(function() {
    var $win = $(window),
     $header = $('.ly_header'),
     $mv = $('.ly_mv'),
     animationClass = 'is-animation';

  $win.on('load scroll', function() {
     var value = $(this).scrollTop();
     if ( value > 5 ) {
     $header.addClass('is-animation');
      $mv.addClass('is-animation');
    } else {
      $header.removeClass('is-animation');
      $mv.removeClass('is-animation');
    }
    });
  });

'use strict'
window.onload = function () {
  const swiper = new Swiper('.mySwiper', {
    spaceBetween: 10,
    slidesPerView: 4,
    watchSlidesProgress: true,
  })
  const swiper2 = new Swiper('.mySwiper2', {
    loop: true,
    speed: 300,
    effect: 'fade',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    thumbs: {
      swiper: swiper,
    },
    on: {
      slideChange: function () {
        if (this.realIndex > 0) {
          this.params.autoplay.delay = 4700
        }
      },
    },
  })
}

$(function() {
  $('.js_nijimi-inview').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $('.un_delay-01').addClass('img-blur');
      $('.un_delay-02').addClass('img-blur');
      $('.un_delay-03').addClass('img-blur');
      $('.un_delay-04').addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_gallery .js_fade-a').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_gallery .js_fade-b').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_gallery .js_fade-c').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_gallery .js_fade-d').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_channel .js_fade-a').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_channel .js_fade-b').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_channel .js_fade-c').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_channel .js_fade-d').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_cd .js_fade-a').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_cd .js_fade-b').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_cd .js_fade-c').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_cd .js_fade-d').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_founder .js_fade-a').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_founder .js_fade-b').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_founder .js_fade-c').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_founder .js_fade-d').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_founder .js_fade-e').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blurB');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_founder .js_fade-f').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_philosophy__box_right .js_fade-a').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_philosophy__box_right .js_fade-b').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_philosophy__box_right .js_fade-c').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_philosophy__box_left .js_fade-d').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_philosophy__box_right .js_fade-e').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_philosophy__box_right .js_fade-f').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_news .js_fade-a').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_news .js_fade-b').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_news .js_fade-c').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_news .js_fade-d').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_news .js_fade-e').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_contact .js_fade-a').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_contact .js_fade-b').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-a').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-b').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-c').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-d').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-e').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-f').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-g').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-h').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-i').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-j').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-k').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$(function() {
  $('.ly_instrument .js_fade-l').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).addClass('img-blur');
    } else {
    //表示領域から出た時
    }
  });
});

$('.ly_contact').hover(
    function() {
     $(this).addClass('img-blur');
    },
    function() {
     $(this).removeClass('img-blur');
    }
);

$(function(){
    $('.js_nijimi-inview').on('inview', function(event, isInView) {
      if (isInView) {
      //表示領域に入った時
      $('.un_delay-01').delay(1000).queue(function() {
        $(this).addClass('img-blur').dequeue();
      });
      $('.un_delay-02').delay(2000).queue(function() {
        $(this).addClass('img-blur').dequeue();
      });
      $('.un_delay-03').delay(3000).queue(function() {
        $(this).addClass('img-blur').dequeue();
      });
      $('.un_delay-04').delay(4000).queue(function() {
        $(this).addClass('img-blur').dequeue();
      });
      } else {
      //表示領域から出た時
      }
    });
});

$(function(){
    $('.ly_channel_img01').on('inview', function(event, isInView) {
      if (isInView) {
      //表示領域に入った時
        $(this).addClass('img-blur').dequeue();
      } else {
      //表示領域から出た時
      }
    });
});

$(function(){
    $('.ly_channel_img02').on('inview', function(event, isInView) {
      if (isInView) {
      //表示領域に入った時
        $(this).addClass('img-blur').dequeue();
      } else {
      //表示領域から出た時
      }
    });
});

$(function(){
    $('.ly_channel_img-bottom').on('inview', function(event, isInView) {
      if (isInView) {
      //表示領域に入った時
        $(this).addClass('img-blur').dequeue();
      } else {
      //表示領域から出た時
      }
    });
});

$(function(){
    $('.un_delay-01c').on('inview', function(event, isInView) {
      if (isInView) {
      //表示領域に入った時
        $(this).addClass('img-blur').dequeue();
      } else {
      //表示領域から出た時
      }
    });
});

$(function(){
    $('.un_delay-01d').on('inview', function(event, isInView) {
      if (isInView) {
      //表示領域に入った時
        $(this).addClass('img-blur').dequeue();
      } else {
      //表示領域から出た時
      }
    });
});

$(function(){
    $('.un_delay-02d').on('inview', function(event, isInView) {
      if (isInView) {
      //表示領域に入った時
        $(this).addClass('img-blur').dequeue();
      } else {
      //表示領域から出た時
      }
    });
});

$(function(){
    $('.js_nijimi-inview').on('inview', function(event, isInView) {
      if (isInView) {
      //表示領域に入った時
      $('.un_delay-01').delay(1000).queue(function() {
        $(this).addClass('img-blur').dequeue();
      });
      $('.un_delay-02').delay(2000).queue(function() {
        $(this).addClass('img-blur').dequeue();
      });
      $('.un_delay-03').delay(3000).queue(function() {
        $(this).addClass('img-blur').dequeue();
      });
      $('.un_delay-04').delay(4000).queue(function() {
        $(this).addClass('img-blur').dequeue();
      });
      } else {
      //表示領域から出た時
      }
    });
});

$(function() {
  $('.side-scroll').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).delay(1000).queue(function(){
          $('.side-scroll').addClass('bg-color');
      })
    } else {
    //表示領域から出た時
      $(this).removeClass('bg-color');
    }
  });
});

$(function() {
  $('.side-scroll').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
      $(this).delay(1000).queue(function(){
          $('.side-scroll').addClass('bg-color');
      })
    } else {
    //表示領域から出た時
      $(this).removeClass('bg-color');
    }
  });
});

$(function() {
  $('.side-scroll-list').on('inview', function(event, isInView) {
    if (isInView) {
    //表示領域に入った時
          $(this).addClass('bg-color');
    } else {
    //表示領域から出た時
      $(this).removeClass('bg-color');
    }
  });
});

var mySwiper = new Swiper ('.swiper-containerB', {
    direction: 'vertical',
    effect: 'slide',
    slidesPerView: 2,
    loop: true,
    speed: 6000,
    autoplay: {
        delay: 0,
        reverseDirection: false,
    },
})

$(function() {
  if (window.matchMedia("(max-width: 768px)").matches) {
    //画面横幅が768px以下のときの処理

  } else {
    //画面横幅が769px以上のときの処理

    /*gsap.to('.js_img-left', {
      y: 140,
      onUpdate: self => {
      if (self.direction === 1) {
        y: 200;
      } else {
        y: 140;
      }
      },
      scrollTrigger: {
        trigger: '.ly_founder',
        start: 'top center',
        end: 'bottom center',
        scrub: true,
        markers: false,
      }
    });*/

    gsap.to('.js_img-right', {
      y: -140,
      scrollTrigger: {
        trigger: '.ly_founder',
        start: 'top center',
        end: 'bottom center',
        scrub: true,
        markers: false,
      }
    });

    gsap.to('.js_img-right', {
      scrollTrigger: {
        trigger: '.ly_founder',
        start: 'top center',
        end: 'bottom center',
        scrub: true,
        markers: false,
      }
    });
  };
})

$(function(){
    $('.ly_instrument > ul li:not(:first-child)').hover(function(){
        $('.ly_instrument > ul li:nth-child(1)').toggleClass('js_select-menu-default');
        $(this).toggleClass('js_select-menu');
    });
});

/* SLIDER */
document.addEventListener('DOMContentLoaded', (event) => {

const sliderContainer = document.querySelector('.slider');
var vertex = `
    varying vec2 vUv;
    void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
`;

var fragment = `
    varying vec2 vUv;

    uniform sampler2D currentImage;
    uniform sampler2D nextImage;
    uniform sampler2D disp;

    uniform float dispFactor;
    float intensity = 0.25;
    void main() {

        vec2 uv = vUv;

        vec4 disp = texture2D(disp, uv);

        vec2 distortedPosition = vec2(uv.x + dispFactor * (disp.r*intensity), uv.y);
        vec2 distortedPosition2 = vec2(uv.x - (1.0 - dispFactor) * (disp.r*intensity), uv.y);

        vec4 _currentImage = texture2D(currentImage, distortedPosition);
        vec4 _nextImage = texture2D(nextImage, distortedPosition2);

        vec4 finalTexture = mix(_currentImage, _nextImage, dispFactor);

        gl_FragColor = finalTexture;
    }
`;

// Scene
let scene = new THREE.Scene();
const imgs = Array.from(document.querySelectorAll('.slide-img'));
const sliderImages=[]
let renderWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
let renderHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

const camera = new THREE.PerspectiveCamera(
    60,
    renderWidth / renderHeight,
    1,
    100
)
camera.position.z = 1

// Renderer
let renderer = new THREE.WebGLRenderer({
    antialias: true,
});
// Renderer opts
renderer.setPixelRatio( window.devicePixelRatio );
renderer.setClearColor( 0x23272A, 1.0 );
renderer.setSize( renderWidth, renderHeight );

// add the renderer to the dom
sliderContainer.appendChild( renderer.domElement );

let textureLoader = new THREE.TextureLoader();
textureLoader.crossOrigin = "anonymous";

imgs.forEach( ( img ) => {
    let image = textureLoader.load( img.getAttribute( 'src' ));
    image.magFilter = image.minFilter = THREE.LinearFilter;
    image.anisotropy = renderer.capabilities.getMaxAnisotropy();
    sliderImages.push( image );
});

let dispImg = textureLoader.load('https://assets.website-files.com/5dc1ae738cab24fef27d7fd2/5dcae913c897156755170518_disp1.jpg');

dispImg.wrapS = dispImg.wrapT = THREE.RepeatWrapping;

let mat = new THREE.ShaderMaterial({
    uniforms: {
        dispFactor: { type: "f", value: 0.0 },
        currentImage: { type: "t", value: sliderImages[0] },
        nextImage: { type: "t", value: sliderImages[1] },
        disp: { type: "t", value: dispImg },
    },
    vertexShader: vertex,
    fragmentShader: fragment,
    transparent: true,
    opacity:1.0
});

let geometry = new THREE.PlaneBufferGeometry(
    2.4,1.16
);

let object = new THREE.Mesh(geometry, mat);
object.position.set(0, 0, 0);
scene.add(object);

window.addEventListener('resize',() => {
    renderer.setSize(window.innerWidth,window.innerHeight);
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
})

$(function() {
  if (window.matchMedia("(max-width: 768px)").matches) {
    //画面横幅が768px以下のときの処理

  } else {
    //画面横幅が769px以上のときの処理

  };
})

let animate = () => {
    requestAnimationFrame(animate);
    renderer.render(scene, camera);
};

animate();

let index = 0;
const slider = document.querySelector('.slider-inner');
const slide = document.querySelectorAll('.slide')
const slideImg = document.querySelector('.slide-background-img')
const next = document.querySelector(".slider-next-btn");
const prev = document.querySelector(".slider-prev-btn");
const slideNum = document.querySelector('.slide-indicator');
const slideSub = document.querySelector('.sub-ttl ul');
const description = document.querySelectorAll('.slide-description');
const title = document.querySelectorAll('.slide-title');
const descriptionArray = Array.from(description);

// Position all description elements 
TweenMax.set(descriptionArray[2],{y:"-100%"})
TweenMax.set(descriptionArray[3],{y:"-200%"})
// Slide to the right 
let autoPlay = true;

let isSliding = false;

let slideTl = (transformVal,transformVal2,elIndex) => {
    const tl = new TimelineMax({
        onStart:()=> {
            isSliding = true
        },
        onComplete:() => {
            isSliding = false
        }
    });
    tl.to(slider,2,{x:transformVal,ease:Sine.easeInOut},'slide')
    .to(slideNum,2,{y:transformVal2,ease:Sine.easeInOut},'slide')
    .to(slideSub,2,{y:transformVal2,ease:Sine.easeInOut},'slide')
    .fromTo(descriptionArray[elIndex],0.25,{y:0},{y:"+=100%",ease:Circ.easeIn},'slide')
    .fromTo(descriptionArray[index],0.3,{y:0},{y:"-=100%"},'slide+=0.75')
    .set(title[elIndex],{className:"-=active"},'slide')
    .set(title[index],{className:"+=active"},'slide')
    return tl;
}
const animateDisplace = () => {
    mat.uniforms.nextImage.value = sliderImages[index];
    mat.uniforms.nextImage.needsUpdate = true;

    TweenLite.to( mat.uniforms.dispFactor, 1, {
        value: 1,
        ease: 'Sine.easeInOut',
        onComplete: () => {
            mat.uniforms.currentImage.value = sliderImages[index];
            mat.uniforms.currentImage.needsUpdate = true;
            mat.uniforms.dispFactor.value = 0.0;   
        }
    });
}
const nextSlide = () => {
    if (isSliding == false) {
        index ++
        slideTl("-=100%","-=25%",index - 1);
        $(".container_circle svg").remove();
        handleProgressbar();
        animateDisplace();
    }
}

const prevSlide = () => {
    if (isSliding == false) {
        index --
        slideTl("+=100%","+=25%",index + 1);
        $(".container_circle svg").remove();
        handleProgressbar();
        animateDisplace();
    }
}

const returnSlide = () => {
    if (isSliding == false) {
        index = 0;
        slideTl("+=300%","+=75%",index - 1);
        $(".container_circle svg").remove();
        handleProgressbar();
        animateDisplace();
    }
}

// ANIMATE SLIDE INDICATOR
const progressEase = "M0,0 C0.092,0.01 0.162,0.108 0.212,0.21 0.328,0.448 0.458,1 1,1";
const progressBar = CSSRulePlugin.getRule('.slide-progress-bar:after');
const progressTl = (delay) => {
    const tl = new TimelineMax({
        repeat:-1,
        paused:false,
        delay:delay
    });
    tl.fromTo(progressBar,2,{cssRule:{x:"-100%"}},{cssRule:{x:"0%"},ease:CustomEase.create("custom",progressEase)})
        .add(() => {
            if (index <= 3 && autoPlay === true) {
                nextSlide();
                TweenMax.to(progressBar,1,{cssRule:{x:"100%"},ease:CustomEase.create("custom",progressEase)})
            }
            if (index === 4 && autoPlay === true) {
                returnSlide();
            }
        })
    return tl;
}
const animateProgress = progressTl(0.4);

// Reset progress bar on hover
const resetProgress = () => {
    if (index < 3 && autoPlay === true) {
        TweenMax.to(animateProgress,
            1 * animateProgress.progress(),{
                progress:0,
                onComplete:()=> {
                    animateProgress.progress(0)
                    animateProgress.stop();
                }
            }
        )
    }
}
// Change Timeline state 
const changeState = (timeline) => {
    if (timeline.progress() == 1) {
        timeline.reverse()
    }
    else {
        timeline.play()
    } 
}

// ANIMATE SEARCH ICON
const searchBtn = document.querySelector('.search-btn')
const searchInput = document.querySelector('.search-input')
const searchTl = () => {
    const tl = new TimelineLite({paused:true});
    tl.to(searchInput,0.15,{width:"225px",fontSize:"16px"},'in')

    return tl;
}
const animateSearch = searchTl()

// ANIMATE MENU ICON
const menuBtn = document.querySelector('.menu-btn')
const menu = document.querySelector('.menu');
const menuTl = () => {
    const tl = new TimelineLite({paused:true})
    tl.to(menu,0.2,{x:0,ease:Sine.easeInOut},'in')
    return tl;
}
const animateMenu = menuTl()

// Click Event listener slider buttons
next.addEventListener('click',() => {
    if (index < 3) {
        nextSlide();
    } 
})
prev.addEventListener('click',() => {
    if (index > 0) {
        prevSlide();
    }
})

// Hover Event listeners for buttons
next.addEventListener('mouseenter',() => {
    resetProgress();   
})
prev.addEventListener('mouseenter',() => {
    resetProgress();  
})

next.addEventListener('mouseleave',() => {
    animateProgress.progress(0)
    animateProgress.restart()
})
prev.addEventListener('mouseleave',() => {
    animateProgress.progress(0)
    animateProgress.restart()
})

// IMAGESLOADED
const loader = document.querySelector('.preloader'),
loaderOutline = document.querySelector('#preloader-outline'),
loaderBot = document.querySelector('#preloader-bot'),
snow = document.querySelectorAll('#snowGroup path');

TweenMax.set('svg',{visibility:"visible"})
TweenMax.set(loaderOutline,{drawSVG:"0% 0%"})
TweenMax.set(snow,{drawSVG:"0% 0%"})
TweenMax.set(loaderBot,{drawSVG:"50% 50%"})

var imgToLoad = document.querySelectorAll('img , canvas');
var imgLoad = imagesLoaded(imgToLoad)

let loadedCount = 0
let loadingProgress = 0

const imgLoadTl = new TimelineMax({
    onComplete:() => {
        TweenLite.to(loader,0.4,{opacity:0})
        TweenLite.set(loader,{visibility:"hidden",delay:0.4})
        // Start sliding -- animate the progress bar 
        animateProgress.play();
    }
});
imgLoadTl
    .to(loaderOutline,1,{drawSVG:"0% 100%"},'in')
    .to(loaderBot,1.5,{drawSVG:"0% 100%"},'in')
    .staggerTo(snow,0.4,{drawSVG:"0% 100%"},0.35,'in+=0.45')

imgLoad.on('progress',() => {
    loadedCount++
    let loadingProgress = loadedCount / imgToLoad.length;
    // Animate the progress of the logo animation
    TweenLite.to(imgLoadTl,0.5,{progress:loadingProgress,ease:Linear.easeNone})
});

/**
 * プログレスバー
 */
function handleProgressbar() {
  const dotActiveItem = document.querySelector('.container_circle');
  const progressCircleBar = new ProgressBar.Circle(dotActiveItem, {
    strokeWidth: 1,
    duration: 3840,
    color: '#fff',
    trailColor: 'rgba(255,255,255,0.6)',
    trailWidth: 1,
    svgStyle: null  
  });
  progressCircleBar.animate(1.0);
}

handleProgressbar();

});

$(function() {
  if (window.matchMedia("(max-width: 768px)").matches) {
    //画面横幅が768px以下のときの処理
  } else {
    //画面横幅が769px以上のときの処理
    $('.ly_instrument > ul li:nth-child(1)').hover(
        function() {
          $('.ly_instrument__result ul li:nth-child(1)').addClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(2)').removeClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(3)').removeClass('js_visible');
        },
        function() {
          $('.ly_instrument__result ul li:nth-child(1)').removeClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(2)').removeClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(3)').removeClass('js_visible');
        }
    );

    $('.ly_instrument > ul li:nth-child(2)').hover(
        function() {
            $('.ly_instrument__result ul li:nth-child(1)').removeClass('js_visible-default');
          $('.ly_instrument__result ul li:nth-child(1)').removeClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(2)').addClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(3)').removeClass('js_visible');
        },
        function() {
            $('.ly_instrument__result ul li:nth-child(1)').addClass('js_visible-default');
          $('.ly_instrument__result ul li:nth-child(1)').removeClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(2)').removeClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(3)').removeClass('js_visible');
        }
    );

    $('.ly_instrument > ul li:nth-child(3)').hover(
        function() {
            $('.ly_instrument__result ul li:nth-child(1)').removeClass('js_visible-default');
          $('.ly_instrument__result ul li:nth-child(1)').removeClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(2)').removeClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(3)').addClass('js_visible');
        },
        function() {
            $('.ly_instrument__result ul li:nth-child(1)').addClass('js_visible-default');
          $('.ly_instrument__result ul li:nth-child(1)').removeClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(2)').removeClass('js_visible');
          $('.ly_instrument__result ul li:nth-child(3)').removeClass('js_visible');
        }
    );
  };
})

$(function() {
  if (window.matchMedia("(max-width: 768px)").matches) {
    //画面横幅が768px以下のときの処理
    $('.ly_instrument__result ul li:nth-child(1)').addClass('js_visible');
    $('.ly_instrument > ul li:nth-child(1)').click(function() {
      $('.ly_instrument__result ul li:nth-child(1)').addClass('js_visible');
      $('.ly_instrument__result ul li:nth-child(2)').removeClass('js_visible');
      $('.ly_instrument__result ul li:nth-child(3)').removeClass('js_visible');
    })
    $('.ly_instrument > ul li:nth-child(2)').click(function() {
        $('.ly_instrument__result ul li:nth-child(1)').removeClass('js_visible-default');
      $('.ly_instrument__result ul li:nth-child(1)').removeClass('js_visible');
      $('.ly_instrument__result ul li:nth-child(2)').addClass('js_visible');
      $('.ly_instrument__result ul li:nth-child(3)').removeClass('js_visible');
    })
    $('.ly_instrument > ul li:nth-child(3)').click(function() {
        $('.ly_instrument__result ul li:nth-child(1)').removeClass('js_visible-default');
      $('.ly_instrument__result ul li:nth-child(1)').removeClass('js_visible');
      $('.ly_instrument__result ul li:nth-child(2)').removeClass('js_visible');
      $('.ly_instrument__result ul li:nth-child(3)').addClass('js_visible');
    })
  } else {
    //画面横幅が769px以上のときの処理

  };
})

$(function() {
  if (window.matchMedia("(max-width: 768px)").matches) {
    //画面横幅が768px以下のときの処理
  } else {

    //画面横幅が769px以上のときの処理
      const listWrapperEl = document.querySelector('.side-scroll');
      const listEl = document.querySelector('.side-scroll-list');

      gsap.to(listEl, {
        x: () => -(listEl.clientWidth - listWrapperEl.clientWidth),
        ease: Power2.easeInOut,
        scrollTrigger: {
          trigger: '.side-scroll',
          start: 'top top+=30', // 要素の上端（top）が、ビューポートの上端（top）にきた時
          end: () => `+=${listEl.clientWidth - listWrapperEl.clientWidth}`,
          scrub: true,
          pin: true,
          markers: false,
          anticipatePin: 1,
          invalidateOnRefresh: true,
        },
      });
  };
})

var beforePos = 0;//スクロールの値の比較用の設定

//スクロール途中でヘッダーが消え、上にスクロールすると復活する設定を関数にまとめる
function ScrollAnime() {
    var elemTop = $('.header-area').offset().top;//#area-2の位置まできたら
  var scroll = $(window).scrollTop();
    //ヘッダーの出し入れをする
    if(scroll == beforePos) {
    //IE11対策で処理を入れない
    }else if(elemTop > scroll || 0 > scroll - beforePos){
    //ヘッダーが上から出現する
    $('header').removeClass('UpMove'); //#headerにUpMoveというクラス名を除き
    $('header').addClass('DownMove');//#headerにDownMoveのクラス名を追加
    }else {
    //ヘッダーが上に消える
    $('header').removeClass('DownMove');//#headerにDownMoveというクラス名を除き
    $('header').addClass('UpMove');//#headerにUpMoveのクラス名を追加
    }

    beforePos = scroll;//現在のスクロール値を比較用のbeforePosに格納
}

// 画面をスクロールをしたら動かしたい場合の記述
$(window).scroll(function () {
  ScrollAnime();//スクロール途中でヘッダーが消え、上にスクロールすると復活する関数を呼ぶ
});

// ページが読み込まれたらすぐに動かしたい場合の記述
$(window).on('load', function () {
  ScrollAnime();//スクロール途中でヘッダーが消え、上にスクロールすると復活する関数を呼ぶ
});

//リンク先のidまでスムーススクロール
//※ページ内リンクを行わない場合は不必要なので削除してください
var headerH = $("header").outerHeight(true);//headerの高さを取得    
  $('#g-navi li a').click(function () {
  var elmHash = $(this).attr('href'); 
  var pos = $(elmHash).offset().top-headerH;//header分の高さを引いた高さまでスクロール
  $('body,html').animate({scrollTop: pos}, 1000);
  return false;
});

$(function() {
  if (window.matchMedia("(max-width: 768px)").matches) {
    //画面横幅が768px以下のときの処理
  } else {
    //画面横幅が769px以上のときの処理
    gsap.to('.un_delay-04', {
      scrollTrigger: {
        trigger: '.un_delay-04',
        start: 'top center',
        end: 'bottom center',
        scrub: true,
        markers: false,
      }
    });

    gsap.to('.js_nijimi-inview', {
      scrollTrigger: {
        trigger: '.js_nijimi-inview',
        start: 'top 250px',
        end: 'bottom center',
        scrub: false,
        markers: false,
        toggleClass: { targets: ".un_delay-01", className: "fadeout" },
      }
    });

    gsap.to('.js_nijimi-inview', {
      scrollTrigger: {
        trigger: '.js_nijimi-inview',
        start: 'top 150px',
        end: 'bottom center',
        scrub: false,
        markers: false,
        toggleClass: { targets: ".un_delay-02", className: "fadeout" },
      }
    });

    gsap.to('.js_nijimi-inview', {
      scrollTrigger: {
        trigger: '.js_nijimi-inview',
        start: 'top 50px',
        end: 'bottom center',
        scrub: false,
        markers: false,
        toggleClass: { targets: ".un_delay-03", className: "fadeout" },
      }
    });

    gsap.to('.js_nijimi-inview', {
      scrollTrigger: {
        trigger: '.js_nijimi-inview',
        start: 'top -250px',
        end: 'bottom -600px',
        scrub: false,
        markers: false,
        delay: 2,
        toggleClass: { targets: ".un_delay-04", className: "img-in" },
      }
    });

  };
});

$(function() {
  if (window.matchMedia("(max-width: 768px)").matches) {
    //画面横幅が768px以下のときの処理

  } else {
    //画面横幅が769px以上のときの処理

    gsap.to('.js_slide-left', {
      y: -100,
      scrollTrigger: {
        trigger: '.ly_founder',
        start: 'top center',
        end: 'bottom center',
        scrub: 0.6,
        markers: false,
      }
    });

    gsap.to('.js_slide-right', {
      y: 100,
      scrollTrigger: {
        trigger: '.ly_founder',
        start: 'top center',
        end: 'bottom center',
        scrub: 0.6,
        markers: false,
      }
    });

    gsap.to('.js_slide-right', {
      scrollTrigger: {
        trigger: '.ly_founder',
        start: 'top center',
        end: 'bottom center',
        scrub: 0.6,
        markers: false,
      }
    });
  };
})

$(function(){
  $('.js_slide-left').slick({
    autoplay: true, //自動でスクロール
    vertical: true,
    autoplaySpeed: 0, //自動再生のスライド切り替えまでの時間を設定
    speed: 9000, //スライドが流れる速度を設定
    cssEase: "linear", //スライドの流れ方を等速に設定
    slidesToShow: 2, //表示するスライドの数
    swipe: false, // 操作による切り替えはさせない
    arrows: false, //矢印非表示
    infinite: true,
    pauseOnFocus: false, //スライダーをフォーカスした時にスライドを停止させるか
    pauseOnHover: false, //スライダーにマウスホバーした時にスライドを停止させるか
    rtl: false, //スライダーを左から右に流す（逆向き）
  });
});

$(function(){
  $('.js_slide-right').slick({
    autoplay: true, //自動でスクロール
    vertical: true,
    autoplaySpeed: 0, //自動再生のスライド切り替えまでの時間を設定
    speed: 9000, //スライドが流れる速度を設定
    cssEase: "linear", //スライドの流れ方を等速に設定
    slidesToShow: 2, //表示するスライドの数
    swipe: false, // 操作による切り替えはさせない
    arrows: false, //矢印非表示
    infinite: true,
    pauseOnFocus: false, //スライダーをフォーカスした時にスライドを停止させるか
    pauseOnHover: false, //スライダーにマウスホバーした時にスライドを停止させるか
    rtl: false, //スライダーを左から右に流す（逆向き）
  });
});

let swiperA = new Swiper(".js_vertical-sliderA", {
  loop: true,
  speed: 2000,
  slidesPerView: 2,
  direction: "vertical",
  autoplay: {
    delay: 0,
  },
});

let swiperB = new Swiper(".js_vertical-sliderB", {
  loop: true,
  speed: 2000,
  slidesPerView: 2,
  direction: "vertical",
  autoplay: {
    delay: 0,
    reverseDirection: true,
  },
});

gsap.config({
  nullTargetWarn: false,
});
